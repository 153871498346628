import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatNativeDateModule } from '@angular/material/core';

import { IsPrismaXSPipe } from './pipes/is-prisma-xs.pipe';
import { HeaderComponent } from './components/header/header.component';
import { UploadlogoComponent } from './components/uploadlogo/uploadlogo.component';
import { LoadingComponent } from './components/loading/loading.component';
import { FooterComponent } from './components/footer/footer.component';
import { TooltipDirective } from './directives/tooltip.directive';
import { CheckboxUiComponent } from './components/checkbox/checkbox.component';
import { BomTableComponent } from './components/bom-table/bom-table.component';
import { BomCopyToButtonComponent } from './components/bom-copy-to-button/bom-copy-to-button.component';
import { BomDiscountComponent } from './components/bom-discount/bom-discount.component';
import { TotalPricePipe } from './pipes/total-price.pipe';
import { ImageComponent } from './components/image/image.component';
import { ProjectCreateComponent } from './components/project-create/project-create.component';
import { TrimTextPipe } from './pipes/trim-text.pipe';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { TitleComponent } from './components/title/title.component';
import { AccordionComponent } from './components/accordions/accordion/accordion.component';
import { AccordionsComponent } from './components/accordions/accordions.component';
import { CatalogueSearchComponent } from './components/catalogue-search/catalogue-search.component';
import { KeyBoardDirective } from './directives/key-board.directive';
import { GridDirective } from './directives/grid.directive';
import { UprightsDirective } from './directives/uprights.directive';
import { EnclosureConversionPipe } from './pipes/enclosure-conversion.pipe';
import { SearchArticlePipe } from './pipes/search-article.pipe';
import { EnclosureSocketPipe } from './pipes/enclosure-socket.pipe';
import { SameHeightEnclosurePipe } from './pipes/same-height-enclosure.pipe';
import { SmallestArticleGroupPipe } from './pipes/smallest-article-group.pipe';
import { GridComponent } from './components/grid/grid.component';
import { WidgetGridModule } from '../widget-grid/widget-grid.module';
import { EnclosureGridComponent } from './components/enclosure-grid/enclosure-grid.component';
import { MenuContextComponent } from './components/menu-context/menu-context.component';
import { ConfiguratorCatalogBadgeComponent } from './components/configurator-catalog-badge/configurator-catalog-badge.component';
import { ZoomControlsComponent } from './components/zoom-controls/zoom-controls.component';
import { WidgetDeviceResizerComponent } from './components/widget-device-resizer/widget-device-resizer.component';
import { WidgetDeviceResizerDirective } from './components/widget-device-resizer/widget-resizer-directive/widget-device-resizer.directive';
import { DeviceSlotNumberPipe } from './pipes/device-slot-number.pipe';
import { CatalogProductImagePipe } from './pipes/catalog-product-image.pipe';
import { FontSizeZoomLevelPipe } from './pipes/font-size-zoom-level.pipe';
// tslint:disable-next-line:max-line-length
import { ConfiguratorCatalogPrismaXSBadgeComponent } from './components/configurator-catalog-prisma-xsbadge/configurator-catalog-prisma-xsbadge.component';
import { SelectCheckboxPipe } from './pipes/select-checkbox.pipe';
import { DisableCopyPipe } from './pipes/disable-copy.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { ButtonCollapseComponent } from './components/button-collapse/button-collapse.component';
import { WidgetImagePipe } from './pipes/widget-image.pipe';
import { DisableMultipleClickDirective } from './directives/disable-multiple-click.directive';
import { SubHeaderComponent } from './components/sub-header/sub-header.component';
import { CountQuantityBomTablePipe } from './pipes/count-quantity-bom-table.pipe';
import { AutocompleteDropdownComponent } from './components/autocomplete-dropdown/autocomplete-dropdown.component';
import { HighlightPipe } from './pipes/highlight.pipe';
import { EnclosureGridReferenceComponent } from './components/enclosure-grid-reference/enclosure-grid-reference.component';
import { ScrollIntoViewDirective } from './directives/scroll-into-view.directive';
import { EnclosureInformationComponent } from './components/enclosure-information/enclosure-information.component';
import { EnclosureImagePipe } from './pipes/enclosure-image.pipe';
import { KitGroupPipe } from './pipes/kit-group.pipe';
import { GroupTypePipe } from './pipes/group-type.pipe';
import { HideElementDirective } from './directives/hide-element.directive';
import { EnclosureBorderDirective } from './directives/enclosure-border.directive';
import { DisableMultipleClickWithNoneEventDirective } from './directives/disable-multiple-click-with-none-event.directive';
import { UploadlogoImageComponent } from './components/uploadlogo/uploadlogo-image/uploadlogo-image.component';
// tslint:disable-next-line:max-line-length
import { UploadlogoPersonalDetailsComponent } from './components/uploadlogo/uploadlogo-personal-details/uploadlogo-personal-details.component';
import { ProductRangeNameComponent } from './components/product-range-name/product-range-name.component';
import { IsEnclosureTypeSelectedPipe } from './pipes/is-enclosure-type-selected.pipe';
import { ClickOutsideEnclosuresDirective } from './directives/click-outside-enclosures.directive';
import { CompleteMultimediaNotAvailableForEnclosureTypePipe } from './pipes/complete-multimedia-not-available-for-enclosure-type.pipe';
import { ArticleCounterComponent } from './components/article-counter/article-counter.component';
import { TooltipInfoDirective } from './directives/tooltip-info.directive';
import { DocumentTypesComponent } from './components/document-types/document-types.component';
import { SecondMenuContextComponent } from './components/second-menu-context/second-menu-context.component';
import { DocumentSizeLimitationComponent } from './components/documents-size-limitation/document-size-limitation/document-size-limitation.component';
import { DocumentFileSizeUsedToPercentagePipe } from './pipes/document-file-size-used-to-percentage.pipe';
import { IsStatusActivePipe } from './pipes/is-status-active.pipe';
import { RemoveInactiveUsersPipe } from './pipes/remove-inactive-users.pipe';
import { MakeBoldPartOfTextPipe } from './pipes/make-bold-part-of-text.pipe';
import { IsSubstringPartOfStringPipe } from './pipes/is-substring-part-of-string.pipe';
import { UniversalDeviceCheckModalComponent } from './components/universal-device-check-modal/universal-device-check-modal.component';
import { IsProductDeprecatedPipe } from './pipes/is-product-deprecated.pipe';
import { DeprecatedProductComponent } from './components/deprecated-product/deprecated-product.component';
import { DeprecatedProductAlertComponent } from './components/deprecated-product-alert/deprecated-product-alert.component';
import { CompanyLogoProportionPipe } from './pipes/company-logo-proportion.pipe';
import { ZoomOutUnavailablePipe } from './pipes/zoom-out-unavailable.pipe';
import { ZoomInUnavailablePipe } from './pipes/zoom-in-unavailable.pipe';
import { ZoomOutSwitchboardUnavailablePipe } from './pipes/zoom-out-switchboard-unavailable.pipe';
import { ZoomInSwitchboardUnavailablePipe } from './pipes/zoom-in-switchboard-unavailable.pipe';
import { InitializeDropdownDirective } from './directives/initialize-dropdown.directive';
import { DeleteProjectWithMembersModalComponent } from './components/delete-project-with-members-modal/delete-project-with-members-modal.component';
import { InvitationMemberModalComponent } from './components/invitation-member-modal/invitation-member-modal.component';
import { TransferOwnershipConfirmationModalComponent } from './components/transfer-ownership-confirmation-modal/transfer-ownership-confirmation-modal.component';
import { GuestInformationComponent } from './components/guest-information/guest-information.component';
import { ProductInfoDocumentLinkPipe } from './pipes/product-info-document-link.pipe';
import { ReleaseUpdatesComponent } from './components/release-updates/release-updates.component';
import { CanEditProjectPipe } from './pipes/can-edit-project.pipe';
import { BomCopyModalComponent } from './components/bom-copy-modal/bom-copy-modal.component';
import { CountTotalPriceBomPipe } from './pipes/count-total-price-bom.pipe';
import { MinimumCountOnePipe } from './pipes/minimum-count-one.pipe';
import { BomSelectDropdownComponent } from './components/bom-select-dropdown/bom-select-dropdown.component';
import { IndeterminateCheckboxDirective } from './directives/indeterminate-checkbox.directive';
import { BomViewDropdownComponent } from './components/bom-view-dropdown/bom-view-dropdown.component';
import { DocumentsDownloadButtonComponent } from './components/documents-download-button/documents-download-button.component';
import { DocumentsTableComponent } from './components/documents-table/documents-table.component';
import { SelectCheckboxDocumentPipe } from './pipes/select-checkbox-document.pipe';
import { BomPdfXlsxIconComponent } from './components/bom-pdf-xlsx-icon/bom-pdf-xlsx-icon.component';
import { ShoppingCartButtonComponent } from './components/shopping-cart-button/shopping-cart-button.component';
import { ShowWhiButtonPipe } from './pipes/show-whi-button.pipe';
import { IndicateSortPipe } from './pipes/indicate-sort.pipe';
import { SumBomQuantitiesPipe } from './pipes/sum-bom-quantities.pipe';
import { SubProjectBomQuantitiesPipe } from './pipes/sub-project-bom-quantities.pipe';
import { CheckIfSelectedPipe } from './pipes/check-if-selected.pipe';
import { IsCustomDeviceBomModelPipe } from './pipes/is-custom-device-bom-model.pipe';
import { IsTherePreWiringDevicesPipe } from './pipes/is-there-pre-wiring-devices.pipe';
import { WholeSalerConfirmationModalComponent } from './components/whole-saler-confirmation-modal/whole-saler-confirmation-modal.component';
import { ConvertZeroToEmptyPipe } from './pipes/convert-zero-to-empty.pipe';
import { FeedbackButtonComponent } from './components/feedback-button/feedback-button.component';
import { HoverTooltipComponent } from './components/hover-tooltip/hover-tooltip.component';
import { BomCopyModalContentComponent } from './components/bom-copy-modal/bom-copy-modal-content/bom-copy-modal-content.component';
import { FilterOutSelectedItemsPipe } from './pipes/filter-out-selected-items.pipe';
import { FilterOutSelectedEnclosuresPipe } from './pipes/filter-out-selected-enclosures.pipe';
import { GenerateBomRadioButtonIdPipe } from './pipes/generate-bom-radio-button-id.pipe';
import { CountNumberOfPositionsBOMPipe } from './pipes/count-number-of-positions-bom.pipe';
import { Is280MmDeepPrismaXsEnclosurePipe } from './pipes/is-280-mm-deep-prisma-xs-enclosure.pipe';
import { IsDeviceForbiddenFor210MmPrismaXsEnclosurePipe } from './pipes/is-device-forbidden-for-210-mm-prisma-xs-enclosure.pipe';
import { HighlightOnChangeComponent } from './components/highlight-on-change/highlight-on-change.component';
import { TransferOwnershipModalComponent } from './components/transfer-ownership-modal/transfer-ownership-modal.component';
import { SelectionResizerDirective } from './directives/selection-resizer.directive';
import { BomRadioDeliveryOptionsComponent } from './components/bom-radio-delivery-options/bom-radio-delivery-options.component';
import { MapToNgSelectListPipe } from './pipes/map-to-ng-select-list.pipe';
import { NotificationsComponent } from './components/notifications/notifications.component';
import { NotificationsButtonComponent } from './components/notifications-button/notifications-button.component';
import { ToastModule } from '../toast/toast.module';
import { MapToNgSelectPipe } from './pipes/map-to-ng-select.pipe';
import { SettingsDropdownIconComponent } from './components/settings-dropdown-icon/settings-dropdown-icon.component';
import { SldConnectionModalComponent } from './components/sld-connection-modal/sld-connection-modal.component';
import { SelectCurrentSourceModalComponent } from './components/sld-connection-modal/select-current-source-modal/select-current-source-modal.component';
import { ZoomControlsSldComponent } from './components/zoom-controls-sld/zoom-controls-sld.component';
import { IsPrismaXsEnclosurePipe } from './pipes/is-prisma-xs-enclosure.pipe';
import { GroupUniversalDevicesPipe } from './pipes/group-universal-devices.pipe';
import { DisableMultipleClickForSldStartingPointDirective } from './directives/disable-multiple-click-for-sld-starting-point.directive';
import { FeedingTreeConflictModalComponent } from './components/feeding-tree-conflict-modal/feeding-tree-conflict-modal.component';
import { FallbackEnToDePipe } from './pipes/fallback-en-to-de.pipe';
import { ShowNewBadgeComponent } from './components/show-new-badge/show-new-badge.component';
import { ProgressBarComponent } from './components/progress-bar/progress-bar.component';
import { FileDownloadInProgressComponent } from './components/file-download-in-progress/file-download-in-progress.component';
import { IsProgressAboveLimitPipe } from './pipes/is-progress-above-limit.pipe';
import { GetAvailableModulesTextPipe } from './pipes/get-available-modules-text.pipe';
import { BomPdfIconEnabledPipe } from './pipes/bom-pdf-icon-enabled.pipe';
import { WholesalerUnableToTransferPipe } from './pipes/wholesaler-unable-to-transfer.pipe';
import { CanTransferToWholesalerPipe } from './pipes/can-transfer-to-wholesaler.pipe';
import { SameWithEnclosurePipe } from './pipes/same-with-enclosure.pipe';
import { ThereAreDeprecatedProductsPipe } from './pipes/there-are-deprecated-products.pipe';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { MultipleMonthsDatepickerComponent } from './components/multiple-months-datepicker/multiple-months-datepicker.component';
import { DateFromMonthPipe } from './pipes/date-from-month.pipe';
import { RangeMonthsHeaderComponent } from './components/multiple-months-datepicker/range-months-header/range-months-header.component';
import { ResetDateComponent } from './components/multiple-months-datepicker/reset-date/reset-date.component';
import { ShoppingCartElbridgeTwoButtonComponent } from './components/shopping-cart-elbridge-two-button/shopping-cart-elbridge-two-button.component';
import { MapAllowedRatedCurrentToSelectListPipe } from './pipes/map-allowed-rated-current-to-select-list.pipe';
import { WholesalerUnableToTransferDevicesExistPipe } from './pipes/wholesaler-unable-to-transfer-devices-exist.pipe';
import { PreWiringDevicesIncludedPipe } from './pipes/pre-wiring-devices-included.pipe';
import { NumberOfBomPositionsPipe } from './pipes/number-of-bom-positions.pipe';
import { WholesalerUnableToTransferProjectDevicesExistPipe } from './pipes/wholesaler-unable-to-transfer-project-devices-exist.pipe';
import { PreWiringProjectDevicesIncludedPipe } from './pipes/pre-wiring-project-devices-included.pipe';
import { NumberOfProjectBomPositionsPipe } from './pipes/number-of-project-bom-positions.pipe';
import { BomStockLevelComponent } from './components/bom-stock-level/bom-stock-level.component';
import { ProductRangeCardNameComponent } from './components/product-range-card-name/product-range-card-name.component';
import { BomAddAdditionalComponentsModal } from './components/bom-add-additional-components-modal/bom-add-additional-components-modal';
import { AddAdditionalComponentsArticleCounterComponent } from './components/add-additional-components-article-counter/add-additional-components-article-counter.component';
import { AdditionalComponentsAddedInCataloguePipe } from './pipes/additional-compnents-added-in-catalogue.pipe';
import { NumberOfAdditionalComponentsInCartPipe } from './pipes/number-of-additional-components-in-cart.pipe';
import { AddAdditionalComponentsFromCatalogueModalComponent } from './components/bom-add-additional-components-modal/add-additional-components-from-catalogue-modal/add-additional-components-from-catalogue-modal.component';
import { BomModelByReferencePipe } from './pipes/bom-model-by-reference.pipe';
import { ExistingAdditionalComponentsQuantityInCataloguePipe } from './pipes/existing-additional-components-quantity-in-catalogue.pipe';
import { NoProductsAddedPipe } from './pipes/no-products-added.pipe';
import { BomDirectFilterSelectedPipe } from './pipes/bom-direct-filter-selected.pipe';
import { BomPriwiringFilterSelectedPipe } from './pipes/bom-priwiring-filter-selected.pipe';

@NgModule({
  declarations: [
    GuestInformationComponent,
    IsSubstringPartOfStringPipe,
    MakeBoldPartOfTextPipe,
    HeaderComponent,
    UploadlogoComponent,
    IsPrismaXSPipe,
    TooltipDirective,
    CheckboxUiComponent,
    LoadingComponent,
    FooterComponent,
    BomTableComponent,
    ProjectCreateComponent,
    BomCopyToButtonComponent,
    BomDiscountComponent,
    TotalPricePipe,
    ImageComponent,
    TrimTextPipe,
    ConfirmationModalComponent,
    TitleComponent,
    CatalogueSearchComponent,
    AccordionsComponent,
    AccordionComponent,
    UprightsDirective,
    KeyBoardDirective,
    GridDirective,
    EnclosureConversionPipe,
    SearchArticlePipe,
    EnclosureSocketPipe,
    SameHeightEnclosurePipe,
    SmallestArticleGroupPipe,
    CatalogProductImagePipe,
    GridComponent,
    EnclosureGridComponent,
    MenuContextComponent,
    SecondMenuContextComponent,
    ConfiguratorCatalogBadgeComponent,
    ZoomControlsComponent,
    FontSizeZoomLevelPipe,
    EnclosureImagePipe,
    ConfiguratorCatalogPrismaXSBadgeComponent,
    SelectCheckboxPipe,
    DisableCopyPipe,
    WidgetDeviceResizerComponent,
    WidgetDeviceResizerDirective,
    ScrollIntoViewDirective,
    DeviceSlotNumberPipe,
    WidgetImagePipe,
    ClickOutsideDirective,
    ButtonCollapseComponent,
    SubHeaderComponent,
    DisableMultipleClickDirective,
    CountQuantityBomTablePipe,
    AutocompleteDropdownComponent,
    HighlightPipe,
    EnclosureGridReferenceComponent,
    EnclosureInformationComponent,
    KitGroupPipe,
    GroupTypePipe,
    HideElementDirective,
    EnclosureBorderDirective,
    DisableMultipleClickWithNoneEventDirective,
    UploadlogoImageComponent,
    UploadlogoPersonalDetailsComponent,
    ProductRangeNameComponent,
    IsEnclosureTypeSelectedPipe,
    ClickOutsideEnclosuresDirective,
    CompleteMultimediaNotAvailableForEnclosureTypePipe,
    ArticleCounterComponent,
    TooltipInfoDirective,
    DocumentTypesComponent,
    DocumentSizeLimitationComponent,
    DocumentFileSizeUsedToPercentagePipe,
    IsStatusActivePipe,
    RemoveInactiveUsersPipe,
    UniversalDeviceCheckModalComponent,
    IsProductDeprecatedPipe,
    DeprecatedProductComponent,
    DeprecatedProductAlertComponent,
    CompanyLogoProportionPipe,
    ZoomOutUnavailablePipe,
    ZoomInUnavailablePipe,
    ZoomOutSwitchboardUnavailablePipe,
    ZoomInSwitchboardUnavailablePipe,
    InitializeDropdownDirective,
    DeleteProjectWithMembersModalComponent,
    InvitationMemberModalComponent,
    TransferOwnershipConfirmationModalComponent,
    ProductInfoDocumentLinkPipe,
    ReleaseUpdatesComponent,
    BomCopyModalComponent,
    CountTotalPriceBomPipe,
    MinimumCountOnePipe,
    BomSelectDropdownComponent,
    IndeterminateCheckboxDirective,
    BomViewDropdownComponent,
    CanEditProjectPipe,
    DocumentsDownloadButtonComponent,
    DocumentsTableComponent,
    SelectCheckboxDocumentPipe,
    BomPdfXlsxIconComponent,
    ShoppingCartButtonComponent,
    ShowWhiButtonPipe,
    IndicateSortPipe,
    SumBomQuantitiesPipe,
    SubProjectBomQuantitiesPipe,
    CheckIfSelectedPipe,
    IsCustomDeviceBomModelPipe,
    IsTherePreWiringDevicesPipe,
    WholeSalerConfirmationModalComponent,
    ConvertZeroToEmptyPipe,
    FeedbackButtonComponent,
    HoverTooltipComponent,
    BomCopyModalContentComponent,
    FilterOutSelectedItemsPipe,
    FilterOutSelectedEnclosuresPipe,
    GenerateBomRadioButtonIdPipe,
    CountNumberOfPositionsBOMPipe,
    Is280MmDeepPrismaXsEnclosurePipe,
    IsDeviceForbiddenFor210MmPrismaXsEnclosurePipe,
    HighlightOnChangeComponent,
    TransferOwnershipModalComponent,
    SelectionResizerDirective,
    BomRadioDeliveryOptionsComponent,
    MapToNgSelectListPipe,
    NotificationsComponent,
    NotificationsButtonComponent,
    MapToNgSelectPipe,
    SettingsDropdownIconComponent,
    SldConnectionModalComponent,
    SelectCurrentSourceModalComponent,
    ZoomControlsSldComponent,
    IsPrismaXsEnclosurePipe,
    GroupUniversalDevicesPipe,
    DisableMultipleClickForSldStartingPointDirective,
    FeedingTreeConflictModalComponent,
    FallbackEnToDePipe,
    ShowNewBadgeComponent,
    ProgressBarComponent,
    FileDownloadInProgressComponent,
    IsProgressAboveLimitPipe,
    GetAvailableModulesTextPipe,
    BomPdfIconEnabledPipe,
    WholesalerUnableToTransferPipe,
    CanTransferToWholesalerPipe,
    SameWithEnclosurePipe,
    ThereAreDeprecatedProductsPipe,
    DatePickerComponent,
    MultipleMonthsDatepickerComponent,
    DateFromMonthPipe,
    RangeMonthsHeaderComponent,
    ResetDateComponent,
    ShoppingCartElbridgeTwoButtonComponent,
    MapAllowedRatedCurrentToSelectListPipe,
    WholesalerUnableToTransferDevicesExistPipe,
    PreWiringDevicesIncludedPipe,
    NumberOfBomPositionsPipe,
    WholesalerUnableToTransferProjectDevicesExistPipe,
    PreWiringProjectDevicesIncludedPipe,
    NumberOfProjectBomPositionsPipe,
    BomStockLevelComponent,
    ProductRangeCardNameComponent,
    BomAddAdditionalComponentsModal,
    AddAdditionalComponentsArticleCounterComponent,
    AdditionalComponentsAddedInCataloguePipe,
    NumberOfAdditionalComponentsInCartPipe,
    AddAdditionalComponentsFromCatalogueModalComponent,
    BomModelByReferencePipe,
    ExistingAdditionalComponentsQuantityInCataloguePipe,
    NoProductsAddedPipe,
    BomPriwiringFilterSelectedPipe,
    BomDirectFilterSelectedPipe
  ],
  exports: [
    IsPrismaXsEnclosurePipe,
    ZoomControlsSldComponent,
    SelectionResizerDirective,
    BomRadioDeliveryOptionsComponent,
    MapToNgSelectPipe,
    MapToNgSelectListPipe,
    CountNumberOfPositionsBOMPipe,
    FilterOutSelectedEnclosuresPipe,
    FilterOutSelectedItemsPipe,
    IsCustomDeviceBomModelPipe,
    SubProjectBomQuantitiesPipe,
    SumBomQuantitiesPipe,
    FeedbackButtonComponent,
    GuestInformationComponent,
    InitializeDropdownDirective,
    IsProductDeprecatedPipe,
    IsSubstringPartOfStringPipe,
    MakeBoldPartOfTextPipe,
    HeaderComponent,
    UploadlogoComponent,
    IsPrismaXSPipe,
    EnclosureImagePipe,
    LoadingComponent,
    FooterComponent,
    TooltipDirective,
    CheckboxUiComponent,
    BomCopyToButtonComponent,
    BomDiscountComponent,
    BomTableComponent,
    BomCopyToButtonComponent,
    BomDiscountComponent,
    TotalPricePipe,
    ImageComponent,
    ProjectCreateComponent,
    ConfirmationModalComponent,
    TrimTextPipe,
    TitleComponent,
    AccordionsComponent,
    AccordionComponent,
    CatalogueSearchComponent,
    UprightsDirective,
    KeyBoardDirective,
    GridDirective,
    EnclosureConversionPipe,
    SearchArticlePipe,
    EnclosureSocketPipe,
    SameHeightEnclosurePipe,
    SmallestArticleGroupPipe,
    CatalogProductImagePipe,
    GridComponent,
    EnclosureGridComponent,
    MenuContextComponent,
    SecondMenuContextComponent,
    ConfiguratorCatalogBadgeComponent,
    DeleteProjectWithMembersModalComponent,
    ZoomControlsComponent,
    ConfiguratorCatalogPrismaXSBadgeComponent,
    WidgetDeviceResizerComponent,
    DeviceSlotNumberPipe,
    ClickOutsideDirective,
    ButtonCollapseComponent,
    WidgetImagePipe,
    DisableMultipleClickDirective,
    DisableMultipleClickWithNoneEventDirective,
    SubHeaderComponent,
    EnclosureGridReferenceComponent,
    EnclosureInformationComponent,
    KitGroupPipe,
    GroupTypePipe,
    HideElementDirective,
    EnclosureBorderDirective,
    ProductRangeNameComponent,
    AutocompleteDropdownComponent,
    IsEnclosureTypeSelectedPipe,
    ClickOutsideEnclosuresDirective,
    CompleteMultimediaNotAvailableForEnclosureTypePipe,
    TooltipInfoDirective,
    DocumentTypesComponent,
    DocumentSizeLimitationComponent,
    DocumentFileSizeUsedToPercentagePipe,
    RemoveInactiveUsersPipe,
    UniversalDeviceCheckModalComponent,
    DeprecatedProductComponent,
    DeprecatedProductAlertComponent,
    InitializeDropdownDirective,
    InvitationMemberModalComponent,
    TransferOwnershipConfirmationModalComponent,
    ProductInfoDocumentLinkPipe,
    ReleaseUpdatesComponent,
    BomCopyModalComponent,
    CountTotalPriceBomPipe,
    BomSelectDropdownComponent,
    IndeterminateCheckboxDirective,
    BomViewDropdownComponent,
    CanEditProjectPipe,
    DocumentsDownloadButtonComponent,
    DocumentsTableComponent,
    SelectCheckboxDocumentPipe,
    BomPdfXlsxIconComponent,
    ShoppingCartButtonComponent,
    ShowWhiButtonPipe,
    IndicateSortPipe,
    CheckIfSelectedPipe,
    IsTherePreWiringDevicesPipe,
    WholeSalerConfirmationModalComponent,
    ConvertZeroToEmptyPipe,
    HoverTooltipComponent,
    Is280MmDeepPrismaXsEnclosurePipe,
    IsDeviceForbiddenFor210MmPrismaXsEnclosurePipe,
    HighlightOnChangeComponent,
    TransferOwnershipModalComponent,
    SettingsDropdownIconComponent,
    SldConnectionModalComponent,
    GroupUniversalDevicesPipe,
    DisableMultipleClickForSldStartingPointDirective,
    FeedingTreeConflictModalComponent,
    FallbackEnToDePipe,
    DisableCopyPipe,
    SelectCheckboxPipe,
    ShowNewBadgeComponent,
    ProgressBarComponent,
    FileDownloadInProgressComponent,
    WholesalerUnableToTransferPipe,
    CanTransferToWholesalerPipe,
    SameWithEnclosurePipe,
    ThereAreDeprecatedProductsPipe,
    DatePickerComponent,
    MultipleMonthsDatepickerComponent,
    ShoppingCartElbridgeTwoButtonComponent,
    MapAllowedRatedCurrentToSelectListPipe,
    WholesalerUnableToTransferDevicesExistPipe,
    PreWiringDevicesIncludedPipe,
    NumberOfBomPositionsPipe,
    WholesalerUnableToTransferProjectDevicesExistPipe,
    PreWiringProjectDevicesIncludedPipe,
    NumberOfProjectBomPositionsPipe,
    ProductRangeCardNameComponent,
    BomAddAdditionalComponentsModal,
    AdditionalComponentsAddedInCataloguePipe,
    NumberOfAdditionalComponentsInCartPipe,
    AddAdditionalComponentsFromCatalogueModalComponent,
    BomModelByReferencePipe,
    ExistingAdditionalComponentsQuantityInCataloguePipe,
    NoProductsAddedPipe,
    BomDirectFilterSelectedPipe,
    BomPriwiringFilterSelectedPipe
  ],
  imports: [
    FormsModule,
    RouterModule,
    CommonModule,
    ReactiveFormsModule,
    WidgetGridModule,
    OverlayModule,
    DragDropModule,
    ToastModule,
    MatFormFieldModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule
  ]
})
export class UIModule {
}

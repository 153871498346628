import { ElementRef } from '@angular/core';

import { IDocumentLoaderStates } from '../../shared/models/document.model';
import { IApartment } from '../../enclosure/models/apartment.model';
import { IEnclosure } from '../../shared/models/enclosure.model';
import { ICompanyAddress } from '../../enclosure/models/document-settings.model';
import { DocumentsTypes } from '../../enclosure/models/documents-types.constants';
import { EnclosureTypes } from '../../enclosure/models/enclosure-type.model';

export const getTitleFromDocumentsTableComponent = (documentEk: ElementRef, suFix: string): string => {
  return documentEk.nativeElement.getAttribute('title') + ' - ' + suFix;
}

export const getTitleFromLegendsDocumentTableComponent = (documentEk: ElementRef, apartmentName: string, suFix: string): string => {
  return documentEk.nativeElement.getAttribute('title') + ' ' + apartmentName + ' - ' + suFix;
}

export const getFolderNameForZippedDocuments = (name: string, suFix: string) => {
  if(!name && !suFix) {
    return 'Untitled.zip';
  }

  if(!name) {
    return suFix + '.zip';
  } else if (!suFix) {
    return name + '.zip';
  }

  return name + ' - ' + suFix + '.zip';
}

export const isAnyDownloadInProgress = (documentLoaderStates: IDocumentLoaderStates): boolean => {
  return !Object.values(documentLoaderStates).every(value => value === false);
}

export const isEnclosureSpecificDocumentDownloadInProgress = (data: IApartment[] | IEnclosure[]) => {
  return data?.some(d => d?.isDownloadInProgress);
}

export const getCompanyAddress = (street: string, city: string, zip: string): ICompanyAddress => {
  return {
    city,
    street,
    zip
  } as ICompanyAddress;
}

export const setAllSelectedDocumentsByEnclosureType = (enclosure: IEnclosure) => {
  switch (enclosure?.enclosureType) {
    case EnclosureTypes.NORMAL:
      return [
        DocumentsTypes.BOM_WITH_PRICE,
        DocumentsTypes.BOM_WITHOUT_PRICE,
        DocumentsTypes.DISPOSITION_LAYOUT,
        DocumentsTypes.DISPOSITION_LAYOUT_WITH_COVERS,
        DocumentsTypes.DISPOSITION_LABELS,
        DocumentsTypes.DISPOSITION_LABELS_BACKSIDE,
        DocumentsTypes.RATING_PLATE,
        DocumentsTypes.SLD,
        DocumentsTypes.ROUTINE_VERIFICATION
      ];
    case EnclosureTypes.PRAGMA:
      return [
        DocumentsTypes.BOM_WITH_PRICE,
        DocumentsTypes.BOM_WITHOUT_PRICE,
        DocumentsTypes.DISPOSITION_LAYOUT,
        DocumentsTypes.DISPOSITION_LAYOUT_WITH_COVERS,
        DocumentsTypes.ENCLOSURE_LAYOUT,
        DocumentsTypes.ENCLOSURE_LAYOUT_WITH_COVERS,
        DocumentsTypes.DISPOSITION_LABELS,
        DocumentsTypes.DISPOSITION_LABELS_BACKSIDE,
        DocumentsTypes.RATING_PLATE,
        DocumentsTypes.SLD,
        DocumentsTypes.ROUTINE_VERIFICATION
      ];
    case EnclosureTypes.PRISMA_XS:
      return [
        DocumentsTypes.BOM_WITH_PRICE,
        DocumentsTypes.BOM_WITHOUT_PRICE,
        DocumentsTypes.DISPOSITION_LAYOUT,
        DocumentsTypes.DISPOSITION_LAYOUT_WITH_COVERS,
        DocumentsTypes.ENCLOSURE_LAYOUT,
        DocumentsTypes.ENCLOSURE_LAYOUT_WITH_COVERS,
        DocumentsTypes.DISPOSITION_LABELS,
        DocumentsTypes.DISPOSITION_LABELS_BACKSIDE,
        DocumentsTypes.RATING_PLATE,
        DocumentsTypes.ROUTINE_VERIFICATION
      ];
  }
}

import { IProductRange } from '../../shared/models/product.model';
import { IEnclosureSpecs, IEnclosureSpecsWithDimensions } from '../../shared/models/enclosure.model';

export interface IEnclosureType {
  predefinedEnclosureSettingsId: number;
  productRangeId: number;
  productRange: IProductRange;
  enclosureSpecs: IEnclosureSpecs[] | IEnclosureSpecsWithDimensions[];
  allowedRatedCurrents: string[];
  defaultRatedCurrent: string;
  /* @TODO consider removing this */
  isSelected?: boolean;
  displayWizard: number;
  isDefault: number;
  pragmaMultiCustomized?: boolean;
  prismaXS?: boolean;
  normal?: boolean;
  specification: IEnclosureTypeSpecification;
}

export enum EnclosureTypes {
  NORMAL,
  PRAGMA,
  PRISMA_XS
}

interface IEnclosureTypeSpecification {
  model: string,
  description: string,
  feature: string,
  material: string,
  type: string
}
